import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Container,
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import printIcon from '../../assets/print.svg';
import saveIcon from '../../assets/save.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';

class Screen extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            modules: [],
            items: [

            ],
            articleTypes: [],
            groups: [],
            subgroups: [],
            manufacturers: [],
            atc: [],
            shapes: [],
            modes: [],
            attributes: [],
            categories: []
        }
    }
    componentDidMount() {
        this._onLoad();



    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {


        let params = this._getParams();
        console.log(this.state)
        return (
            <div>
                <div className="section-title title-options">
                    <h1>{'Adds'.translate(this.props.lang)}</h1>
                </div>
                {this.state.articleForm ?
                    <div className="table-tabs">
                        <button onClick={() => this.setState({ articleForm: false })}>
                            {'Adds List'.translate(this.props.lamg)}
                        </button>
                        <button className="active" onClick={() => this.setState({ articleForm: {} })}>
                            {'Add new add'.translate(this.props.lang)}
                        </button>
                    </div>
                    : null}

                {
                    this.state.articleForm ?

                        <div>
                            <div className="side-margin">

                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    classNameForm={"add-form-container"}
                                    // headerForm={"Artikal".translate(this.props.lang)}
                                    //                                  addButtonText={this.state.articleForm._id ? 'Edit product'.translate(this.props.lang) :  'Add new'.translate(this.props.lang)}
                                    lang={this.props.lang}
                                    onSubmit={(data) => {
                                        if (this.state.articleForm && this.state.articleForm._id) {

                                            this._api('updateAd', { data: data, langs: this.props.lang }, () => {
                                                this.setState({ articleForm: undefined }, () => { this._onLoad(); })
                                            });
                                        } else {
                                            this._api('makeAd', { data: data }, () => {
                                                this.setState({ articleForm: {} }, () => { this._onLoad() })
                                            });
                                        }
                                    }}
                                    initialValues={this.state.articleForm && this.state.articleForm._id ? this.state.articleForm : {}}
                                    fields={[


                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: { lg: 10, sm: 10, xs: 10 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Add info'.translate(this.props.lang),
                                                            style: { paddingBottom: 33 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'specialtext',
                                                                            multilang: true,
                                                                            next: 'title',
                                                                            label: 'Special Text'
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'headertext',
                                                                            multilang: true,
                                                                            next: 'title',
                                                                            label: 'Job Title',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Job title is required!'),
                                                                                async (value) => {
                                                                                    console.log(value)
                                                                                    try {
                                                                                        if (value) {
                                                                                            let res = await this._apiAsync('validate', { value: value, id: this.state.articleForm._id ? this.state.articleForm._id : null });
                                                                                            if (res.status == 400) {
                                                                                                return 'Error'
                                                                                            }
                                                                                            return undefined;
                                                                                        }

                                                                                    } catch (e) {
                                                                                        return undefined;
                                                                                    }
                                                                                },
                                                                            ]
                                                                        },
                                                                        // {
                                                                        //     type: 'text',
                                                                        //     name: 'descriptiontext',
                                                                        //     multilang: true,
                                                                        //     next: 'descriptiontext',
                                                                        //     label: 'Minor Text',


                                                                        // },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'buttontext',
                                                                            multilang: true,
                                                                            next: 'buttontext',
                                                                            label: 'Button Text'
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'shorttext',
                                                                            multilang: true,
                                                                            next: 'shorttext',
                                                                            label: 'Short Job Description',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Short Description text is required!'),
                                                                                async (value) => {
                                                                                    console.log(value)
                                                                                    try {
                                                                                        if (value) {
                                                                                            let res = await this._apiAsync('validate', { value: value, id: this.state.articleForm._id ? this.state.articleForm._id : null });
                                                                                            if (res.status == 400) {
                                                                                                return 'Error'
                                                                                            }
                                                                                            return undefined;
                                                                                        }

                                                                                    } catch (e) {
                                                                                        return undefined;
                                                                                    }
                                                                                },
                                                                            ]
                                                                        },
                                                                        //    {
                                                                        //        type: 'text',
                                                                        //        name: 'timeexpired',
                                                                        //        next: 'Time expire',
                                                                        //        label: 'Time expire',


                                                                        //    },
                                                                        {
                                                                            type: 'html',
                                                                            name: 'descriptionlong',
                                                                            multilang: true,
                                                                            next: 'long',
                                                                            label: 'Job Description'
                                                                        },




                                                                        {
                                                                            type: 'image',
                                                                            width: { lg: 4, sm: 2, xs: 6 },
                                                                            name: 'images',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Banner image',
                                                                            className: 'image',
                                                                            validate: [
                                                                            ]

                                                                        },
                                                                        {
                                                                            type: 'button',
                                                                            width: { lg: 12, sm: 6, xs: 6 },
                                                                            action: 'submit',
                                                                            children: 'Save',
                                                                            className: 'form-button'
                                                                        }




                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]

                                                }
                                            ]
                                        }

                                    ]}
                                ></FormBuilder>
                            </div>

                        </div>

                        :

                        <div>
                            {
                                console.log(this.state.items)

                            }
                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {
                                    this._api('deleteAd', { data: this.state.deleteModal._id }, () => {
                                        this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                                    });
                                }} >
                                {'Delete Ad'} <strong>{this.state.deleteModal ? Object.translate(this.state.deleteModal, 'headertext', this.props.lang) : ''}</strong> ?
                            </DeleteModal>
                            <ListBuilder
                                tabs={true}
                                sort={true}
                                tab1Name={'Adds List'.translate(this.props.lang)}
                                tab2Name={"Add new ad".translate(this.props.lang)}
                                onClickTab1={() => {
                                    this.setState({
                                        articlesList: true,
                                        articleForm: false,
                                    })
                                }}
                                onClickTab2={() => {
                                    this.setState({
                                        articleForm: true,
                                        articlesList: false
                                    })
                                }}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'headertext', label: 'Title'.translate(this.props.lang), multilang: true, allowSort: true },

                                ]}
                                items={this.state.items}


                                actions={
                                    [
                                        {
                                            component: <Isvg src={editIcon} />,
                                            onClick: (item) => this.setState({ articleForm: item })
                                        },
                                        {
                                            component: <Isvg src={garbageIcon} />,
                                            onClick: (item) => this.setState({ deleteModal: item })
                                        },
                                    ]
                                }

                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </div>
                }


            </div>
        )
    }
}

export default Page(Screen);
