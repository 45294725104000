import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';


class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            form: false,
            total: 20,
            items: [

            ],
            articleTypes: [],
            groups: [],
            subgroups: [],
            manufacturers: [],
            atc: [],
            shapes: [],
            modes: [],
            attributes: [],
            categories: [],
        }
    }



    componentDidMount() {
        this._onLoad();

        this._api('categories', {}, (data) => {
            this.setState({ categories: data.categories })
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    render() {
        let params = this._getParams();

        console.log(this.state.categories)
        return (
            <div>
                <div className="section-title title-options">
                    <h1>{'Home config'.translate(this.props.lang)}</h1>
                </div>

                {
                    this.state.form ?

                        <div>
                            <div className="side-margin">
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    classNameForm={"add-form-container"}
                                    // headerForm={"Artikal".translate(this.props.lang)}
                                    //                                  addButtonText={this.state.form._id ? 'Edit product'.translate(this.props.lang) :  'Add new'.translate(this.props.lang)}
                                    lang={this.props.lang}
                                    onSubmit={(data) => {

                                        this._api('save', { data: data }, () => {
                                            this.setState({ form: {} }, () => { this._onLoad() })
                                        });

                                    }}
                                    initialValues={this.state.form}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [



                                                {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Brand #1'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [


                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'brand[0].link',
                                                                            next: 'password',
                                                                            label: 'Link',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'brand[0].image',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        }]
                                                },

                                                {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Brand #2'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [


                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'brand[1].link',
                                                                            next: 'password',
                                                                            label: 'Link',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'brand[1].image',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        }]
                                                },

                                                {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Brand #3'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [


                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'brand[2].link',
                                                                            next: 'password',
                                                                            label: 'Link',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'brand[2].image',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        }]
                                                },

                                                {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Brand #4'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [


                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'brand[3].link',
                                                                            next: 'password',
                                                                            label: 'Link',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'brand[3].image',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        }]
                                                },


  {
                                                    type: 'col',
                                                    width: { lg: 2, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Brand #5'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [


                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'brand[4].link',
                                                                            next: 'password',
                                                                            label: 'Link',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'brand[4].image',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        }]
                                                },



                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Section #2'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[1].title',
                                                                            next: 'password',
                                                                            label: 'Title',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[1].subtitle',
                                                                            next: 'password',
                                                                            label: 'Subtitle',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[1].button.text',
                                                                            next: 'password',
                                                                            label: 'Button text',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[1].button.link',
                                                                            next: 'password',
                                                                            label: 'Button link',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'section[1].image',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },




                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                    ]
                                                                },




                                                            ]
                                                        },



                                                    ]
                                                },


                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Section #3'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[2].title',
                                                                            next: 'password',
                                                                            label: 'Title',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[2].subtitle',
                                                                            next: 'password',
                                                                            label: 'Subtitle',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[2].button.text',
                                                                            next: 'password',
                                                                            label: 'Button text',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[2].button.link',
                                                                            next: 'password',
                                                                            label: 'Button link',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'section[2].image',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },




                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                    ]
                                                                },




                                                            ]
                                                        },



                                                    ]
                                                },


                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Section #4'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[3].title',
                                                                            next: 'password',
                                                                            label: 'Title',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[3].text',
                                                                            next: 'password',
                                                                            label: 'Text',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[3].button.text',
                                                                            next: 'password',
                                                                            label: 'Button text',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[3].button.link',
                                                                            next: 'password',
                                                                            label: 'Button link',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'section[3].image',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'section[3].mobileImage',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },




                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                    ]
                                                                },




                                                            ]
                                                        },



                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Section #5'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'section[4].link',
                                                                            next: 'password',
                                                                            label: 'Link',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'section[4].image',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'section[4].mobileImage',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },





                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                    ]
                                                                },




                                                            ]
                                                        },



                                                    ]
                                                },

                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Vind Banner'.translate(this.props.lang),
                                                            style: { paddingBottom: 33, marginTop: 20 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'vind.section[0].title',
                                                                            next: 'password',
                                                                            label: 'Title',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'vind.section[0].subtitle',
                                                                            next: 'password',
                                                                            label: 'Subtitle',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'vind.section[0].button.text',
                                                                            next: 'password',
                                                                            label: 'Button text',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'vind.section[0].button.link',
                                                                            next: 'password',
                                                                            label: 'Button link',
                                                                            multilang: true,
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'image',
                                                                            name: 'vind.section[0].image',
                                                                            endpoint: '/data/categories/upload',
                                                                            label: 'Image',
                                                                            validate: [
                                                                            ]
                                                                        },
                                                                    ]
                                                                },




                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                    ]
                                                                },




                                                            ]
                                                        },



                                                    ]
                                                },




                                            ],
                                        },
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'button',
                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                    action: 'submit',
                                                    children: 'Save',
                                                    className: 'form-button',
                                                },

                                            ]
                                        }

                                    ]}
                                ></FormBuilder>
                            </div>

                        </div>
                        :
                        null

                }

            </div>
        )
    }
}

export default Page(Home);

/*

*/
