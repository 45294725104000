import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    FormGroup

} from 'reactstrap';
import { API_ENDPOINT } from '../../constants';

class ProductSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: ''
        };
    }

    search = () => {
        fetch(API_ENDPOINT + '/data/products', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify({
                page: 0,
                entries: 10,
                filter: this.state.search,
                sortType: 1,
                sortField: 'brand',

            })
        }).then(res => res.json()).then((result) => {
            this.setState({
                products: result.items
            })
        })
    }

    render() {
        //console.log(this.props.children);
        return (
            <FormGroup className="product-select">
                {this.props.label ? <Label className={this.props.error ? "required" : ""}>{this.props.label}</Label> : null}

                <Dropdown className={this.props.error ? 'select-field required' : 'select-field'} isOpen={this.state.dropdownOpen} toggle={() => { this.setState({ dropdownOpen: !this.state.dropdownOpen }) }}>
                    <DropdownToggle nav caret>
                        <input type="text" placeholder='Search...' value={this.state.search} onChange={(e) => {
                            this.setState({
                                search: e.target.value
                            }, this.search)
                        }} />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-animation">
                        {

                            this.state.products && this.state.products.map((item) => {
                                if (item)
                                    return (
                                        <DropdownItem className={this.props.value && this.props.value.indexOf && this.props.value.indexOf(item._id) !== -1 ? 'active' : ''} onClick={() => { 
                                            if (this.props.value && this.props.value.indexOf && this.props.value.indexOf(item._id) !== -1){
                                                let value = this.props.value;
                                                value.splice(this.props.value.indexOf(item._id), 1);
                                                this.props.onChange(value);
                                            }else{
                                                let value = this.props.value;
                                                if (!value){
                                                    value = [];
                                                }

                                                value.push(item._id);
                                                this.props.onChange(value);

                                            }
                                         }}>
                                            <img src={API_ENDPOINT.replace('testapi', 'api') + (item && item.thumbnailMood ? item.thumbnailMood.thumbnail : item.thumbnailExposed ? item.thumbnailExposed.thumbnail : null)} /> {(item.brand ? item.brand : '') + ' ' + item.name.sv}
                                        </DropdownItem>
                                    )
                            })
                        }
                    </DropdownMenu>
                </Dropdown>
            </FormGroup>
        );
    }
}

export default ProductSelect;