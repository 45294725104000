import env from "react-dotenv";
import { parseJSON } from "../../helpers/json";
import { API_ENDPOINT } from '../../constants';

export default {
    'api1': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(API_ENDPOINT + '/data/orders', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },

                body: JSON.stringify({
                    page: query.page,
                    entries: query.entries,
                    filter: query.filter,
                    sortType: query.sortType ? query.sortType : -1,
                    sortField: query.sortField ? query.sortField : '_id',
                    b2c: query.b2c ? true : false,
                    employee: query.employee ? true : false,
                    notSent: query.notSent ? true : false,
                    vind: query.vind ? true : false,
                    refunder: query.refunder ? true : false

                })
            }).then(parseJSON).then(({ result, status }) => {
                if (status >= 200 && status < 300)
                    return {
                        items: result.items,
                        total: result.total,
                    }
            })
        }
    },
    'api2': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/orders/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'api3': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/orders/' + data.data, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'api4': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/orders/' + data.data._id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },

    'validate': {
        onLoad: false,
        action: (data) => {
            console.log("VALIDACIJA")
            return fetch(API_ENDPOINT + '/data/orders/validate/' + data.value + '/' + data.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },
    
    'send': {
        onLoad: false,
        action: (data) => {
            return fetch(API_ENDPOINT + '/data/orders/send/' + data.data, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                //body: JSON.stringify(data)
            }).then(parseJSON).then(({ result, status }) => {
                return { result, status };
            })
        }
    },



}