import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import Text from '../../form-builder/fields/text';
import { API_ENDPOINT } from '../../constants';

class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            items: [

            ],
            articleTypes: [],
            groups: [],
            subgroups: [],
            manufacturers: [],
            atc: [],
            shapes: [],
            modes: [],
            attributes: [],
            categories: [],
            
        }


    }



    componentDidMount() {
        this._onLoad();

        this._api('categories', {}, (data) => {
            this.setState({ categories: data.categories })
        })

        let currDate = new Date();
        currDate.setHours(23);
        currDate.setMinutes(59);
        currDate.setSeconds(59);

        let fromDate = new Date(currDate);
        fromDate.setDate(currDate.getDate() - 7);
        currDate.setHours(0);
        currDate.setMinutes(0);
        currDate.setSeconds(0);



        this.setState({
            from: fromDate.toISOString().split('T')[0],
            to: currDate.toISOString().split('T')[0]
        })


    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    export = () => {
        let params = this._getParams();

        fetch(API_ENDPOINT + '/data/clients/export', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },

            body: JSON.stringify({
                type: params.type,
            })
        }).then(res => res.blob()).then(data => { if (data) window.open(URL.createObjectURL(data)) })

    }

    render() {
        let params = this._getParams();
        console.log(this.state)
        console.log(this.state.categories)
        return (
            <div>
                <div className="section-title title-options">
                    <h1>{'Newsletter'.translate(this.props.lang)}</h1>

                    <div className="ff-export">
                        <button onClick={this.export} className="button">Export</button>
                    </div>
                </div>
                {this.state.articleForm ?
                    <div className="table-tabs">
                        <button onClick={() => this.setState({ articleForm: false })}>
                            {'List'.translate(this.props.lamg)}
                        </button>
                    </div>
                    : null}

                {
                    this.state.articleForm ?

                        <div>
                            <div className="side-margin">
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    classNameForm={"add-form-container"}
                                    // headerForm={"Artikal".translate(this.props.lang)}
                                    //                                  addButtonText={this.state.articleForm._id ? 'Edit product'.translate(this.props.lang) :  'Add new'.translate(this.props.lang)}
                                    lang={this.props.lang}
                                    onSubmit={(data) => {
    
                                        if (this.state.articleForm && this.state.articleForm._id) {
                                            console.log(data)
                                            this._api('api4', { data: data }, () => {
                                                this.setState({ articleForm: undefined }, () => { this._onLoad(); })
                                            });
                                        } else {
                                            console.log(data)
                                            this._api('api2', { data: data }, () => {
                                                this.setState({ articleForm: {} }, () => { this._onLoad() })
                                            });
                                        }
                                    }}
                                    initialValues={this.state.articleForm && this.state.articleForm._id ? this.state.articleForm : {}}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Client'.translate(this.props.lang),
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'name',
                                                                            next: 'level',
                                                                            label: 'Name',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Required!'),
                                                                            ]
                                                                        },
                        
                                                                    ]
                                                                },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 12, sm: 12, xs: 12 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'text',
                                                                //             name: 'code',
                                                                //             next: 'level',
                                                                //             label: 'Code',
                                                                //         },
                        
                                                                //     ]
                                                                // },
                                                                                                                               
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'code',
                                                                            next: 'level',
                                                                            label: 'Password',
                                                                        },
                        
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'maxNumberOfUsers',
                                                                            next: 'level',
                                                                            label: 'Max number of users',
                                                                        },
                        
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'linkWith',
                                                                            next: 'level',
                                                                            label: 'Link with customer',
                                                                        },
                        
                                                                    ]
                                                                },
                                                           
                                                            ]
                                                        },

                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'button',
                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                    action: 'submit',
                                                    children: 'Save',
                                                    className: 'form-button',
                                                },

                                            ]
                                        }

                                    ]}
                                ></FormBuilder>
                            </div>

                        </div>

                        :

                        <div>
                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {
                                    this._api('api3', { data: this.state.deleteModal._id }, () => {
                                        this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                                    });
                                }} >
                                {'Delete'.translate(this.props.lang)}?
                            </DeleteModal>
                            <ListBuilder
                                tabs={true}
                                sort={true}
                                tab1Name={'B2B'.translate(this.props.lang)}
                                onClickTab1={() => {
                                    this.updateMultipleParams([{ name: 'entries', value:"10" }, { name: 'page', value: 0 },{name: 'filter',value: ''},{name:'type',value:null}], true);
                                }}
                                tab2Name={'851 - PARTNER'.translate(this.props.lang)}
                                onClickTab2={() => {
                                    this.updateMultipleParams([{ name: 'entries', value:"10" }, { name: 'page', value: 0 },{name: 'filter',value: ''},{name:'type',value:851}], true);
                                }}
                                tab3Name={'908 - VÄNNER'.translate(this.props.lang)}
                                onClickTab3={() => {
                                    this.updateMultipleParams([{ name: 'entries', value:"10" }, { name: 'page', value: 0 },{name: 'filter',value: ''},{name:'type',value:908}], true);
                                }}
                                tab4Name={'909 - SALE'.translate(this.props.lang)}
                                onClickTab4={() => {
                                    this.updateMultipleParams([{ name: 'entries', value:"10" }, { name: 'page', value: 0 },{name: 'filter',value: ''},{name:'type',value:909}], true);
                                }}
                                tab2Active={params.type == 851}
                                tab3Active={params.type == 908}
                                tab4Active={params.type == 909}

                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'email', label: 'EMAIL'.translate(this.props.lang), allowSort: true },
                                    { type: 'text', name: 'username', label: 'USERNAME'.translate(this.props.lang), allowSort: true },

                                    
                                ]}
                                items={this.state.items}


                            params={params}
                            sortField={params.sortField}
                            sortType={params.sortType}
                            updateSort={this.updateSort}
                            updateParams={this.updateParams}
                            updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </div>
                }


            </div>
        )
    }
}

export default Page(Home);

/*

*/