import Screen from './screen';
import API from './api';

export default {
    path: '/login',
    auth: false,
    component: Screen,
    seo: (data, lang) => {
        return {}
    }
}