import env from "react-dotenv";
import { resetSection } from "redux-form";
import { parseJSON } from "../../helpers/json";
import { API_ENDPOINT } from '../../constants';

export default {
    'loadAd': {
        onLoad: true,
        action: (lang, match, query) => {
            return fetch(API_ENDPOINT + '/data/adds', {
                method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`

                    }    ,

                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
    
                    })
                
                }).then(parseJSON).then(({ result, status }) => {
                    console.log(result)
                
                        return {
                            items: result.items,
                        total: result.total
                        }
                    

                })
        }
    },

    'makeAd': {
    
        onLoad: false,
        action: (data) => {
            console.log(data);
			data.data.expired='false';
            return fetch(API_ENDPOINT + '/data/listad/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`

                },
                body: JSON.stringify(data.data)
            }).then(parseJSON).then(({ result, status }) => {
                console.log(result,status)
                return {result, status};
            })
        }
    },
        'updateAd': {
            onLoad: false,
            action: (data) => {
             
                console.log(data)
                return fetch(API_ENDPOINT + '/data/adds/update/' + data.data._id+'/'+data.langs, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    body: JSON.stringify(data.data)
                }).then(parseJSON).then(({ result, status }) => {
                    return { result, status };
                })
            }
        },

        'deleteAd': {
            onLoad: false,
            action: (data) => {
                return fetch(API_ENDPOINT + '/data/adds/delete/' + data.data, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    //body: JSON.stringify(data)
                }).then(parseJSON).then(({ result, status }) => {
                    return { result, status };
                })
            }
        }
   

}