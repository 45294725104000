import Screen from './screen';
import API from './api';
import Login from '../login/screen';

export default {
    path: '/translate-cache',
    auth: true,
    component: Screen,
    preAuthComponent: Login,
    api: API,
    seo: (data, lang) => { return {} }
}