import React, { Component } from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import {
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import saveIcon from '../../assets/save.svg';
import printIcon from '../../assets/print.svg';
import garbageOpionIcon from '../../assets/garbage-option.svg';
import DeleteModal from '../../components/deleteModal';
import { required, number, minValue, maxValue, minLength, maxLength, length, email } from '../../form-builder/validation';
import { API_ENDPOINT } from '../../constants';

class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            items: [

            ],
            articleTypes: [],
            groups: [],
            subgroups: [],
            manufacturers: [],
            atc: [],
            shapes: [],
            modes: [],
            attributes: [],
            categories: []
        }
    }



    componentDidMount() {
        this._onLoad();

        this._api('categories', {}, (data) => {
            this.setState({ categories: data.categories })
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search != prevProps[0].location.search) {
            this._onLoad();
        }
    }

    export = () => {
        let params = this._getParams();
        console.log('params: ', params.type)
        fetch(API_ENDPOINT + '/data/clients/export?isVerified=clients', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify({
                clients: {
                    type: params.type ? params.type : 'all'
                },
                type: true
            })
        }).then(res => res.blob()).then(data => { if (data) window.open(URL.createObjectURL(data)) })

    }

    render() {
        let params = this._getParams();

        console.log(this.state.categories)
        return (
            <div>
                <div className="section-title title-options">
                    <h1>{'Customers'.translate(this.props.lang)}</h1>

                    <div className="ff-export">
                        <button onClick={this.export} className="button">Export</button>
                    </div>
                </div>
                {this.state.articleForm ?
                    <div className="table-tabs">
                        <button onClick={() => this.setState({ articleForm: false })}>
                            {'Customers list'.translate(this.props.lamg)}
                        </button>
                        {/* <button className="active" onClick={() => this.setState({ articleForm: {} })}>
                            {'Add new customer'.translate(this.props.lang)}
                        </button> */}
                    </div>
                    : null}

                {
                    this.state.articleForm ?

                        <div>
                            <div className="side-margin">
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    classNameForm={"add-form-container"}
                                    // headerForm={"Artikal".translate(this.props.lang)}
                                    //                                  addButtonText={this.state.articleForm._id ? 'Edit product'.translate(this.props.lang) :  'Add new'.translate(this.props.lang)}
                                    lang={this.props.lang}
                                    onSubmit={(data) => {

                                        if (this.state.articleForm && this.state.articleForm._id) {
                                            console.log(data)
                                            this._api('api4', { data: data }, () => {
                                                this.setState({ articleForm: undefined }, () => { this._onLoad(); })
                                            });
                                        } else {
                                            console.log(data)
                                            this._api('api2', { data: data }, () => {
                                                this.setState({ articleForm: {} }, () => { this._onLoad() })
                                            });
                                        }
                                    }}
                                    initialValues={this.state.articleForm && this.state.articleForm._id ? this.state.articleForm : {}}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Account details'.translate(this.props.lang),
                                                            style: { paddingBottom: 33 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'username',
                                                                            next: 'password',
                                                                            label: 'Username',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Username is required!'),
                                                                                // async (value) => {
                                                                                //     try {
                                                                                //         if (value) {
                                                                                //             let res = await this._apiAsync('validate', { value: value, id: this.state.form ? this.state.form._id : null });
                                                                                //             if (res.status == 400) {
                                                                                //                 return 'Username already in use'
                                                                                //             }
                                                                                //             return undefined;
                                                                                //         }

                                                                                //     } catch (e) {
                                                                                //         return undefined;
                                                                                //     }
                                                                                // },
                                                                            ]
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'email',
                                                                            label: 'EMail',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('EMail is required!'),
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'select',
                                                                            name: 'currency',
                                                                            next: 'level',
                                                                            label: 'Currency',
                                                                            multilang: false,
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                required('Required!'),
                                                                            ],
                                                                            values: [{ name: 'SEK', value: 'SEK' }, { name: 'EUR', value: 'EUR' }, { name: 'DKK', value: 'DKK' }, { name: 'USD', value: 'USD' }]
                                                                        },

                                                                    ]
                                                                },


                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        // {
                                                                        //     type: 'text',
                                                                        //     name: 'password',
                                                                        //     label: 'Password',
                                                                        //     asyncValidation: true,
                                                                        // },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'customerData.id',
                                                                            label: 'Customer nr.',
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'customerData.vatNumber',
                                                                            label: 'VAT',
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'customerData.orgNumber',
                                                                            label: 'Organisation nr.',
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'customerData.creditLimit',
                                                                            label: 'Limit',
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'customerData.creditLimitCurrency',
                                                                            label: 'Currency',
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 12, xs: 12 },
                                                                    children: [
                                                                    ]
                                                                },


                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 3, xs: 3 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'verified',
                                                                            next: 'stock',
                                                                            label: 'Verified',
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 3, xs: 3 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'creditCardPayment',
                                                                            next: 'stock',
                                                                            label: 'Credit card payment',
                                                                        }
                                                                    ]
                                                                },

                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 2, sm: 3, xs: 3 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'switch',
                                                                //             name: 'emailVerified',
                                                                //             next: 'stock',
                                                                //             label: 'Verified',
                                                                //         }
                                                                //     ]
                                                                // },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 3, sm: 3, xs: 3 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'newsletter',
                                                                            next: 'stock',
                                                                            label: 'Subcribed to newsletter',
                                                                        }
                                                                    ]
                                                                },




                                                            ]
                                                        },



                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Shipping data'.translate(this.props.lang),
                                                            style: { marginTop: 15, },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'shippingData.firstName',
                                                                            label: 'First name',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'shippingData.lastName',
                                                                            label: 'Last name',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'shippingData.company',
                                                                            label: 'Company',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'shippingData.email',
                                                                            label: 'EMail',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'shippingData.address1',
                                                                            label: 'Address line 1',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'shippingData.address2',
                                                                            label: 'Address line 1',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'shippingData.city',
                                                                            label: 'City',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'shippingData.state',
                                                                            label: 'State',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'shippingData.phone',
                                                                            label: 'Phone',
                                                                        },

                                                                    ]
                                                                },
                                                            ]
                                                        },


                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Billing data'.translate(this.props.lang),
                                                            style: { marginTop: 15, },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'billingData.firstName',
                                                                            label: 'First name',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'billingData.lastName',
                                                                            label: 'Last name',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'billingData.company',
                                                                            label: 'Company',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'billingData.email',
                                                                            label: 'EMail',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'billingData.address1',
                                                                            label: 'Address line 1',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'billingData.address2',
                                                                            label: 'Address line 1',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'billingData.city',
                                                                            label: 'City',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'billingData.state',
                                                                            label: 'State',
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'billingData.phone',
                                                                            label: 'Phone',
                                                                        },

                                                                    ]
                                                                },
                                                            ]
                                                        },


                                                    ]
                                                },







                                                {
                                                    type: 'col',
                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Stedger'.translate(this.props.lang),
                                                            style: { paddingBottom: 33 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'stedgerCatalogId',
                                                                            next: 'password',
                                                                            label: 'Catalog Id',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                            ]
                                                                        },

                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 2, sm: 3, xs: 3 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'stedger',
                                                                            next: 'stock',
                                                                            label: 'Enabled',
                                                                        }
                                                                    ]
                                                                },




                                                            ]
                                                        },



                                                    ]
                                                },
                                            ],
                                        },
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'button',
                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                    action: 'submit',
                                                    children: 'Save',
                                                    className: 'form-button',
                                                },

                                            ]
                                        }

                                    ]}
                                ></FormBuilder>
                            </div>

                        </div>

                        :

                        <div>
                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {
                                    this._api('api3', { data: this.state.deleteModal._id }, () => {
                                        this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                                    });
                                }} >
                                {'Delete customer'.translate(this.props.lang)} <strong>{this.state.deleteModal ? this.state.deleteModal.name[this.props.lang] : ''}</strong> ?
                            </DeleteModal>
                            <ListBuilder
                                tabs={true}
                                sort={true}
                                tab1Name={'Customers list'.translate(this.props.lang)}
                                // tab2Name={"Add new customer".translate(this.props.lang)}
                                onClickTab1={() => {
                                    this.setState({
                                        articlesList: true,
                                        articleForm: false,
                                    })
                                    this.updateMultipleParams([{ name: 'entries', value: "10" }, { name: 'page', value: 0 }, { name: 'filter', value: '' }], true);
                                }}
                                onClickTab2={() => {
                                    this.setState({
                                        articleForm: true,
                                        articlesList: false
                                    })
                                }}
                                additionalFilterFields={<>
                                    <Label>
                                        {'Type'.translate(this.props.lang)} &nbsp;

                                        <Input style={{ minWidth: 200 }} type="select" className="entries" value={params.type ? params.type : 'all'} onChange={(e) => this.updateMultipleParams([{ name: 'type', value: e.target.value }, { name: 'page', value: 0 }], true)}>
                                            <option value={'all'}>All</option>
                                            <option value={'b2b'}>B2B</option>
                                            <option value={'partner'}>851 Partner</option>
                                            <option value={'friends'}>908 Friends</option>
                                            <option value={'sale'}>909 Sale</option>
                                            <option value={'refunder'}>1145 Refunder</option>
                                            <option value={'vind'}>1179 Vind</option>
                                            <option value={'verified'}>Verified</option>
                                            <option value={'non-verified'}>Non Verified</option>
                                        </Input>
                                    </Label>

                                </>}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={[
                                    { type: 'text', name: 'customerData.id', label: 'CUSTOMER NR.'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'username', label: 'USERNAME'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'email', label: 'EMAIL'.translate(this.props.lang), multilang: false, allowSort: true },
                                    { type: 'text', name: 'customerData.vatNumber', label: 'VAT'.translate(this.props.lang), multilang: false, allowSort: true },

                                ]}
                                items={this.state.items.map((item) => {
                                    return {
                                        ...item,

                                        customerData: {
                                            ...item.customerData,
                                            id: <div style={{ display: 'flex', alignItems: 'center' }}><div className={`status ${item.verified ? 'active' : ''}`}></div> <span>{item.customerData ? item.customerData.id === 'link-909' ? 'Guest' : item.customerData.id : null}</span></div>
                                        }
                                    }
                                })}
                                rawItems={this.state.items}
                                actions={
                                    [
                                        {
                                            component: <Isvg src={editIcon} />,
                                            onClick: (item) => this.setState({ articleForm: item })
                                        },
                                        {
                                            component: <Isvg src={garbageIcon} />,
                                            onClick: (item) => this.setState({ deleteModal: item })
                                        },
                                    ]
                                }

                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </div>
                }


            </div>
        )
    }
}

export default Page(Home);

/*

*/
