import LoginPage from './login';
import ArticlesPage from './articles';
import UsersPage from './users';
import UserLevelsPage from './userLevels';
import ModulesPage from './modules';
import CategoriesPage from './categories';

import CategoriesSortPage from './categories-sort';
import ClientsPage from './clients';
import Coupons from './coupons';
import HomeConfig from './home-config';
import Catalogs from './catalogs';
import TeamPositions from './team-positions';
import Team from './team';
import Locations from './locations';
import Orders from './orders';
import HomeSlides from './home-slides';
import TranslateCache from './translate-cache';
import Translate from './translate';
import CareAdvice from './care-advice';
import adds from './adds';
import Attributes from './attributes';
import FFCodes from './ff-codes';
import ClaimInfo from './calim-info';
import Pages from './pages';
import Newsletter from './newsletter';
import StockClients from './stock-clients';
import VindPages from './vind-pages';

export const routes = [
    LoginPage,
    ArticlesPage,
    UsersPage,
    UserLevelsPage,
    ModulesPage,
    CategoriesPage,
    CategoriesSortPage,
    ClientsPage,
    Coupons,
    HomeConfig,
    Catalogs,
    TeamPositions,
    Team,
    Locations,
    Orders,
    HomeSlides,
    TranslateCache,
    CareAdvice,
    adds,
    Attributes,
    Translate,
    FFCodes,
    ClaimInfo,
    Pages,
    Newsletter,
    StockClients,
    VindPages
]

