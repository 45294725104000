import { Component } from "react";

import { FormGroup, Input, Label, FormFeedback } from 'reactstrap';

class Text extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let value = this.props.value ? new Date(this.props.value * 1000).toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
        console.log(value)
        // console.log(this.props.readOnly ? this.props.val : this.props.value ? this.props.value : '')
        return (
            <FormGroup className="position-relative">
                <Label>{this.props.label}</Label>
                <input className={`${this.props.touched && this.props.error ? 'is-invalid' : this.props.touched ? 'is-valid' : ''} form-control`} type={"date"}
                    ref={(node) => this.props.setFieldRef(node)}
                    onChange={(e) => {
                        this.props.onChange(Math.floor(new Date(e.target.value).getTime() / 1000));
                    }}
                    readOnly={this.props.readOnly}
                    disabled={this.props.disabled}
                    value={value}
                    placeholder={this.props.placeholder}
                ></input>
                {this.props.error && this.props.touched ?
                    <FormFeedback invalid tooltip>{this.props.error}</FormFeedback>
                    :

                    null
                }

            </FormGroup>
        )
    }
}

export default Text;
